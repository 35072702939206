var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"d89d6077ae5c51eb6476d86814edc6ca6e36593e"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import { BrowserTracing, Replay, init } from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

init({
  dsn: SENTRY_DSN,
  environment: process.env.ENV || process.env.NEXT_PUBLIC_ENV,
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.2,
  integrations: [
    new BrowserTracing({
      tracePropagationTargets: [/^\//, /moongate\.id/, /firebaseapp\.com/],
    }),
    new Replay(),
  ],
  enabled: ["production", "staging", "dev"].includes(
    process.env.ENV || process.env.NEXT_PUBLIC_ENV
  ),
  beforeSendTransaction(event) {
    if (!["production", "staging", "dev"].includes(event.environment))
      return null;
    return event;
  },
});
